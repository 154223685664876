import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WhiteboardFullscreenControl } from 'features/whiteboard/WhiteboardFullscreenControl';
import React from 'react';
import { GridPanelCloseControl } from './GridPanelCloseControl';

export const GridPanelControlsContainer = styled(Box)({
  position: 'absolute',
  top: '10px',
  zIndex: 999,
});

GridPanelControlsContainer.defaultProps = {
  className: 'grid-panel-controls-container',
};

export const GridPanelControls = ({ dragHandle }: { dragHandle?: boolean }) => (
  <GridPanelControlsContainer
    className="hover-child"
    sx={{
      right: dragHandle ? '23px' : '10px',
    }}
  >
    <WhiteboardFullscreenControl />
    <GridPanelCloseControl />
  </GridPanelControlsContainer>
);
