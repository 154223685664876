import { signalingFilesReceived } from 'features/content-library/actions';
import { filesReceived } from 'features/content-library/contentLibrarySlice';
import {
  ContentLibraryFile,
  ContentLibraryFileId,
  ContentLibraryRawFile,
  FilesReceivedPayload,
} from 'features/content-library/types';
import { call, put } from 'redux-saga/effects';

const normalizeFilesPayload = (files: ContentLibraryRawFile[]) =>
  files
    // dumb comparison so it's always in english and we don't get weird errors from localeCompare. Hi Hanna.
    .sort((a, b) => {
      // Sort the files alphabetically by name
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .reduce(
      (
        acc: { byId: { [key: string]: ContentLibraryFile }; allIds: ContentLibraryFileId[] },
        file: ContentLibraryRawFile
      ) => {
        if (!file.pages?.length) {
          delete file.pages;
        }
        acc.byId[file.id] = file as ContentLibraryFile;

        acc.allIds.push(file.id);

        return acc;
      },
      { byId: {}, allIds: [] }
    );

export function* onFilesReceivedSaga(action: ReturnType<typeof signalingFilesReceived>) {
  const files: FilesReceivedPayload = yield call(normalizeFilesPayload, action.payload.files);
  yield put(filesReceived(files));
}
