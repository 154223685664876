import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useRecorder } from 'features/recorder/useRecorder';
import { WhiteboardControl } from 'features/whiteboard/WhiteboardControl';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { fileClosed } from '../contentLibrarySlice';

export const FilePanelCloseControl = () => {
  const dispatch = useAppDispatch();
  const isRecorder = useRecorder();

  const { hasPermissions } = usePermissions();

  const canPresent = hasPermissions(PermissionTypes.presentFiles);

  const isFeatureEnabled = useFeatureFlag('whiteboard');

  if (!(isFeatureEnabled && canPresent) || isRecorder) {
    return null;
  }

  const handleClick = () => {
    dispatch(fileClosed());
  };

  return <WhiteboardControl icon="close" onClick={handleClick} />;
};
