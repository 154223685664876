import { selectToolbarPanel, toolbarPanelClosed } from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { put, select } from 'redux-saga/effects';

export function* onContentLibraryPermissionRevokedSaga() {
  const openPanel: ToolbarPanelType | null = yield select(selectToolbarPanel);

  if (openPanel === 'content-library') {
    yield put(toolbarPanelClosed());
  }
}
