import { useContentLibraryControl } from 'features/toolbar/Toolbar/controls/ContentLibrary/useContentLibraryControl';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import React from 'react';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';

const ContentLibraryControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { active, label, toggleContentLibrary, isFeatureAvailable } = useContentLibraryControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleContentLibrary();
  };

  return (
    <ToolbarMenuItem
      id="content-library-control"
      overlay={label}
      ariaLabel={label}
      active={active}
      icon="file"
      onClick={handleClick}
    />
  );
};

export default ContentLibraryControl;
