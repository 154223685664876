import { all, put } from 'redux-saga/effects';
import application from 'features/application/sagas';
import streaming from 'features/streaming/sagas';
import room from 'features/room/sagas';
import layout from 'features/layout/sagas';
import users from 'features/users/sagas';
import recorder from 'features/recorder/sagas';
import chat from 'features/chat/sagas';
import captions from 'features/transcripts/sagas';
import virtualBackground from 'features/virtual-backgrounds/sagas';
import permissions from 'features/permissions/sagas';
import e2ee from 'features/e2ee/sagas';
import notifications from 'features/notifications/sagas';
import breakoutRooms from 'features/breakout-rooms/sagas';
import qa from 'features/qa/sagas';
import features from 'features/feature-set/sagas';
import * as Sentry from '@sentry/react';
import { roomError } from 'features/room/roomSlice';
import { logger } from 'utils/logger';
import contentLibrary from 'features/content-library/sagas';
import polls from 'features/polls/sagas';

function* rootSaga() {
  try {
    yield all([
      application(),
      streaming(),
      captions(),
      room(),
      layout(),
      users(),
      recorder(),
      chat(),
      virtualBackground(),
      permissions(),
      e2ee(),
      notifications(),
      breakoutRooms(),
      qa(),
      contentLibrary(),
      features(),
      polls(),
    ]);
  } catch (error) {
    yield put(roomError({ global: true }));
    Sentry.captureException(error, {
      level: 'fatal',
    });
    // @ts-ignore
    logger.error(`rootSaga: ${error.name}: ${error.message}`);
  }
}

export default rootSaga;
