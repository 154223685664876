import { FeedId } from 'features/streaming/types';
import { User, UserId, UserName } from 'features/users/types';
import { FeedStreamInfo } from 'utils/webrtc';

export type SignalingSendEventType =
  | 'ping'
  | 'joinRoom'
  | 'startStream'
  | 'stopStream'
  | 'startScreenshare'
  | 'stopScreenshare'
  | 'startedTalking'
  | 'endSession'
  | 'leaveSession'
  | 'muteRemoteAudio'
  | 'askToUnmuteRemoteAudio'
  | 'log'
  | 'muteRemoteVideo'
  | 'askToUnmuteRemoteVideo'
  | 'requestRecording'
  | 'recordingStarted'
  | 'recordingStopped'
  | 'stopRecordingRequest'
  | 'recordingFailure'
  | 'sendChatMessage'
  | 'deleteChatMessage'
  | 'raiseHand'
  | 'lowerHand'
  | 'kickUser'
  | 'changeSpokenLanguage'
  | 'allowBroadcast'
  | 'disallowBroadcast'
  | 'allowScreenshare'
  | 'disallowScreenshare'
  | 'allowEditWhiteboard'
  | 'disallowEditWhiteboard'
  | 'changeRole'
  | 'shareEncryptionKey'
  | 'roomNotFound'
  | 'lockRoom'
  | 'unlockRoom'
  | 'admitUserAccess'
  | 'rejectUserAccess'
  | 'bulkAdmitUserAccess'
  | 'bulkRejectUserAccess'
  | 'shareEncryptedName'
  | 'openWhiteboard'
  | 'closeWhiteboard'
  | 'updateWhiteboard'
  | 'updateWhiteboardCursor'
  | 'bulkMuteRemoteAudio'
  | 'requestFileUpload'
  | 'createBreakoutRooms'
  | 'clearBreakoutRooms'
  | 'deleteBreakoutRoom'
  | 'addBreakoutRoom'
  | 'renameBreakoutRoom'
  | 'startBreakoutRooms'
  | 'stopBreakoutRooms'
  | 'assignUsersToBreakoutRoom'
  | 'unassignUserFromBreakoutRoom'
  | 'broadcastBreakoutRoomMessage'
  | 'askQuestion'
  | 'answerQuestion'
  | 'removeUpvoteQuestion'
  | 'upvoteQuestion'
  | 'deleteQuestion'
  | 'deleteAnswer'
  | 'startLiveAnswerQuestion'
  | 'stopLiveAnswerQuestion'
  | 'cancelLiveAnswerQuestion'
  | 'disableFeature'
  | 'enableFeature'
  | 'openFile'
  | 'closeFile'
  | 'createPoll'
  | 'launchPoll'
  | 'endPoll'
  | 'deletePoll'
  | 'votePoll'
  | 'sharePollResults'
  | 'stopSharingPollResults'
  | 'editPoll'
  | 'requestDetailedPollResults'
  | 'adjustAspectRatio'
  | 'openCaptionsPanel'
  | 'closeCaptionsPanel'
  | 'requestDownloadToken'
  | 'startTranscription'
  | 'stopTranscription';

export type SignalingReceivedEventType =
  | 'connected'
  | 'pong'
  | 'streamStarted'
  | 'streamStopped'
  | 'screenshareStarted'
  | 'screenshareStopped'
  | 'disconnect'
  | 'roomJoined'
  | 'userJoined'
  | 'userLeft'
  | 'activeSpeakerChanged'
  | 'sessionEnded'
  | 'sessionLeft'
  | 'participantsLimitReached'
  | 'audioMutedRemotely'
  | 'askedToUnmuteAudioRemotely'
  | 'videoMutedRemotely'
  | 'askedToUnmuteVideoRemotely'
  | 'recordingStarted'
  | 'recordingRequested'
  | 'recordingStopRequested'
  | 'recordingFailure'
  | 'recordingStopped'
  | 'recorderJoined'
  | 'chatMessageSent'
  | 'chatMessageDeleted'
  | 'allChatMessagesReceived'
  | 'speechCaptioned'
  | 'handRaised'
  | 'handLowered'
  | 'kicked'
  | 'broadcastAllowed'
  | 'broadcastDisallowed'
  | 'screenshareAllowed'
  | 'screenshareDisallowed'
  | 'roleChanged'
  | 'systemUpdate'
  | 'sessionNotStarted'
  | 'encryptionKeyShared'
  | 'entryAccessRequired'
  | 'roomLocked'
  | 'roomUnlocked'
  | 'waitingUserJoined'
  | 'waitingUserLeft'
  | 'waitingUserLeftBatch'
  | 'waitingUsersUpdated'
  | 'bulkUserAccessRejected'
  | 'accessRejected'
  | 'publicKeyShared'
  | 'encryptedNameShared'
  | 'whiteboardOpened'
  | 'whiteboardUpdated'
  | 'whiteboardClosed'
  | 'whiteboardCursorUpdated'
  | 'editWhiteboardAllowed'
  | 'editWhiteboardDisallowed'
  | 'roomJoinPaused'
  | 'broadcasterLimitReached'
  | 'breakoutRoomsCreated'
  | 'breakoutRoomsReceived'
  | 'breakoutRoomsCleared'
  | 'breakoutRoomDeleted'
  | 'breakoutRoomRenamed'
  | 'breakoutRoomsStarted'
  | 'breakoutRoomsStopped'
  | 'usersAssignedToBreakoutRoom'
  | 'userUnassignedFromBreakoutRoom'
  | 'fileUploadPrepared'
  | 'breakoutRoomMessageBroadcasted'
  | 'breakoutUserJoined'
  | 'breakoutUserLeft'
  | 'breakoutUserLeftBatch'
  | 'breakoutRoomAdded'
  | 'questionAsked'
  | 'questionAnswered'
  | 'questionUpvoted'
  | 'questionUpvoteRemoved'
  | 'questionDeleted'
  | 'answerDeleted'
  | 'questionLiveAnswerStopped'
  | 'questionLiveAnswerCancelled'
  | 'questionLiveAnswerStarted'
  | 'filesReceived'
  | 'fileOpened'
  | 'fileClosed'
  | 'featureDisabled'
  | 'featureEnabled'
  | 'userLeftBatch'
  | 'pollEnded'
  | 'pollLaunched'
  | 'pollCreated'
  | 'pollsReceived'
  | 'pollDeleted'
  | 'pollVoted'
  | 'pollResultsShared'
  | 'pollResultsSharingStopped'
  | 'pollEdited'
  | 'aspectRatioAdjusted'
  | 'sessionEndsSoon'
  | 'transcriptionStarted'
  | 'transcriptionStopped';

export interface SignalingEventPayload<Data = any> {
  data: Data;
  initiator: EventInitiator;
}

export interface SignalingSendMessage {
  event: SignalingSendEventType;
  requestId?: string;
  data?: any;
}

export interface SignalingReceivedMessage {
  event: SignalingReceivedEventType;
  requestId?: string;
  success?: boolean;
  data?: any;
}

export interface SignalingPong {
  token: string;
  publishingMediaToken: string;
  subscribingMediaToken: string;
}

export interface SystemUpdatePayload {
  token: string;
}

export type SignalingRoomUser = Required<User> &
  Partial<{
    screensharing: boolean;
    screenshareFeedId: FeedId;
    screenshareStreams: FeedStreamInfo[];
    screenshareStartedTimestamp: number;
  }>;

export enum CloseCodes {
  NO_RECONNECT = 4000,
  INTERNAL_ERROR = 4001,
  SYSTEM_KICK = 4002,
  TERMINATED = 4999,
}

export interface EventInitiator {
  id: UserId;
  name?: UserName;
  avatarColor?: string;
  avatarUrl?: string;
  initials?: string;
}

export interface EventUser {
  id: UserId;
  name: UserName;
  avatarColor: string;
  avatarUrl?: string;
  initials?: string;
}

export interface EntityIdPayload {
  id: string;
}

export interface SignalingTokenResponse {
  token: string;
}
