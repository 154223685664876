import { onWhiteboardPermissionsGrantedSaga } from 'features/permissions/sagas/onWhiteboardPermissionsGrantedSaga';
import { onWhiteboardPermissionsRevokedSaga } from 'features/permissions/sagas/onWhiteboardPermissionsRevokedSaga';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  allowBroadcast,
  allowEditWhiteboard,
  allowScreenshare,
  broadcastPermissionGranted,
  broadcastPermissionRevoked,
  changeRoleRequest,
  contentLibraryPermissionRevoked,
  disallowBroadcast,
  disallowEditWhiteboard,
  disallowScreenshare,
  signalingBroadcastAllowed,
  signalingBroadcastDisallowed,
  signalingEditWhiteboardAllowed,
  signalingEditWhiteboardDisallowed,
  signalingRoleChanged,
  signalingScreenshareAllowed,
  signalingScreenshareDisallowed,
  whiteboardPermissionGranted,
  whiteboardPermissionRevoked,
} from 'features/permissions/actions';
import { allowBroadcastSaga } from 'features/permissions/sagas/allowBroadcastSaga';
import { disallowBroadcastSaga } from 'features/permissions/sagas/disallowBroadcastSaga';
import { onBroadcastAllowedSaga } from 'features/permissions/sagas/onBroadcastAllowedSaga';
import { onBroadcastDisallowedSaga } from 'features/permissions/sagas/onBroadcastDisallowedSaga';
import { allowScreenshareSaga } from 'features/permissions/sagas/allowScreenshareSaga';
import { disallowScreenshareSaga } from 'features/permissions/sagas/disallowScreenshareSaga';
import { onScreenshareAllowedSaga } from 'features/permissions/sagas/onScreenshareAllowedSaga';
import { onScreenshareDisallowedSaga } from 'features/permissions/sagas/onScreenshareDisallowedSaga';
import { changeRoleSaga } from 'features/permissions/sagas/changeRoleSaga';
import { onRoleChangedSaga } from 'features/permissions/sagas/onRoleChangedSaga';
import { onBroadcastPermissionGrantedSaga } from 'features/permissions/sagas/onBroadcastPermissionGrantedSaga';
import { onBroadcastPermissionRevokedSaga } from 'features/permissions/sagas/onBroadcastPermissionRevokedSaga';
import { allowEditWhiteboardSaga } from 'features/permissions/sagas/allowEditWhiteboardSaga';
import { disallowEditWhiteboardSaga } from 'features/permissions/sagas/disallowEditWhiteboardSaga';
import { onContentLibraryPermissionRevokedSaga } from './onContentLibraryPermissionRevokedSaga';

export default function* permissionsWatcher() {
  yield takeLatest(broadcastPermissionGranted, onBroadcastPermissionGrantedSaga);
  yield takeLatest(broadcastPermissionRevoked, onBroadcastPermissionRevokedSaga);

  yield takeEvery(allowBroadcast, allowBroadcastSaga);
  yield takeEvery(disallowBroadcast, disallowBroadcastSaga);
  yield takeLatest(signalingBroadcastAllowed, onBroadcastAllowedSaga);
  yield takeLatest(signalingBroadcastDisallowed, onBroadcastDisallowedSaga);

  yield takeEvery(allowScreenshare, allowScreenshareSaga);
  yield takeEvery(disallowScreenshare, disallowScreenshareSaga);
  yield takeLatest(signalingScreenshareAllowed, onScreenshareAllowedSaga);
  yield takeLatest(signalingScreenshareDisallowed, onScreenshareDisallowedSaga);

  yield takeEvery(changeRoleRequest, changeRoleSaga);
  yield takeLatest(signalingRoleChanged, onRoleChangedSaga);

  yield takeLatest(whiteboardPermissionGranted, onWhiteboardPermissionsGrantedSaga);
  yield takeLatest(whiteboardPermissionRevoked, onWhiteboardPermissionsRevokedSaga);

  yield takeLatest(contentLibraryPermissionRevoked, onContentLibraryPermissionRevokedSaga);

  yield takeEvery(allowEditWhiteboard, allowEditWhiteboardSaga);
  yield takeEvery(disallowEditWhiteboard, disallowEditWhiteboardSaga);

  yield takeEvery(signalingEditWhiteboardAllowed, onWhiteboardPermissionsGrantedSaga);
  yield takeEvery(signalingEditWhiteboardDisallowed, onWhiteboardPermissionsRevokedSaga);
}
