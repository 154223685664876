import { FilePanelCloseControl } from 'features/content-library/file-panel/FilePanelCloseControl';
import { selectGridPanel } from 'features/layout/features/content/contentSlice';
import { WhiteboardCloseControl } from 'features/whiteboard/WhiteboardCloseControl';
import React from 'react';
import { useAppSelector } from 'store/hooks';

export const GridPanelCloseControl = () => {
  const activePanel = useAppSelector(selectGridPanel);

  if (activePanel === 'whiteboard') {
    return <WhiteboardCloseControl />;
  }

  return <FilePanelCloseControl />;
};
