import {
  fileOpened,
  selectContentLibraryActivePage,
  selectOpenFile,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryFile } from 'features/content-library/types';
import { put, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';

export function* onFileOpenedSaga(action: ReturnType<typeof fileOpened>) {
  const { id, page } = action.payload;

  const openFile: ContentLibraryFile = yield select(selectOpenFile);
  const activePage: number = yield select(selectContentLibraryActivePage);

  if (id === openFile?.id && page === activePage) {
    return;
  }

  SignalingSocket.send({
    event: 'openFile',
    data: { id, page },
  });

  yield put(fileOpened({ id, page }));
}
