import { selectOpenFile } from 'features/content-library/contentLibrarySlice';
import { GalleryFile } from 'features/content-library/file-panel/GalleryFile';
import { SingleImageFile } from 'features/content-library/file-panel/SingleImageFile';
import React from 'react';
import { useAppSelector } from 'store/hooks';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/virtual';

export const FilePanel = ({ size }: { size: { width?: number; height?: number } }) => {
  const file = useAppSelector(selectOpenFile);

  if (!file) {
    return null;
  }

  if ('pages' in file) {
    return <GalleryFile file={file} size={size} />;
  }

  return <SingleImageFile file={file} />;
};
