import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { useTranslation } from 'react-i18next';

export const useContentLibraryControl = () => {
  const { t } = useTranslation('room');

  const { toolbarPanel, togglePanel } = useToolbarPanel();

  const { hasPermissions } = usePermissions();
  const canPresentFiles = hasPermissions(PermissionTypes.presentFiles);

  const featureEnabled = useFeatureFlag('filesPanel');

  const isFeatureAvailable = featureEnabled && canPresentFiles;

  const active = toolbarPanel === 'content-library';

  const toggleContentLibraryPanel = () => {
    togglePanel('content-library');
  };

  return {
    active,
    label: t('toolbar.content_library'),
    toggleContentLibrary: toggleContentLibraryPanel,
    isFeatureAvailable,
  };
};
