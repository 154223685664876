import { thumbnailsToggled } from 'features/content-library/contentLibrarySlice';
import { GridPanelControlsContainer } from 'features/layout/GridPanel/GridPanelControls';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { WhiteboardControl } from 'features/whiteboard/WhiteboardControl';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React from 'react';
import { useAppDispatch } from 'store/hooks';

export const ToggleThumbnailsControl = () => {
  const { hasPermissions } = usePermissions();
  const canPresentFiles = hasPermissions(PermissionTypes.presentFiles);

  const dispatch = useAppDispatch();

  const { isMobile } = useBreakpoints();

  const toggleThumbnails = () => {
    dispatch(thumbnailsToggled());
  };

  if (isMobile || !canPresentFiles) {
    return null;
  }

  return (
    <GridPanelControlsContainer sx={{ top: '5px', left: '0px' }} className="hover-child">
      <WhiteboardControl icon="thumbnail-panel" onClick={toggleThumbnails} />
    </GridPanelControlsContainer>
  );
};
