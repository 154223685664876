import BroadcasterLimitReachedModal from 'features/room/modals/BroadcasterLimitReachedModal';
import WhiteboardE2eeConfirmationModal from 'features/whiteboard/modals/WhiteboardE2eeConfirmationtModal';
import React, { Suspense, useMemo } from 'react';
import RoomSettingsModal from 'features/room/settings/RoomSettingsModal';
import AudioPermissionsError from 'features/room/modals/AudioPermissionsError';
import VideoPermissionsError from 'features/room/modals/VideoPermissionsError';
import ScreensharePermissionsError from 'features/room/modals/ScreensharePermissionsError';
import BlockedDevicesModal from 'features/room/modals/BlockedDevicesModal';
import MuteRemoteAudioConfirmation from 'features/room/modals/MuteRemoteAudioConfirmation';
import { useAppSelector } from 'store/hooks';
import { selectActiveModal } from 'features/modal/modalSlice';
import EndSessionConfirmation from 'features/room/modals/EndSessionConfirmation';
import UnmuteRemoteAudioConfirmation from 'features/room/modals/UnmuteRemoteAudioConfirmation';
import MuteRemoteVideoConfirmation from 'features/room/modals/MuteRemoteVideoConfirmation';
import UnmuteRemoteVideoConfirmation from 'features/room/modals/UnmuteRemoteVideoConfirmation';
import PeerConnectionFailureModal from 'features/room/modals/PeerConnectionFailureModal';
import { ModalName } from 'features/modal/types';
import RecordingConsentModal from 'features/recorder/modals/RecordingConsentModal';
import RecordingFailureModal from 'features/recorder/modals/RecordingFailureModal';
import RecordingFailureRuntimeModal from 'features/recorder/modals/RecordingFailureRuntimeModal';
import StopRecordingConfirmation from 'features/recorder/modals/StopRecordingConfirmation';
import KickUserConfirmation from 'features/room/modals/KickUserConfirmation';
import ManageRole from 'features/room/modals/ManageRole';
import RoomSecurityCodeModal from 'features/room/modals/RoomSecurityCodeModal';
import InviteUserModal from 'features/room/modals/InviteUserModal';
import ClearActionNotificationsConfirmation from 'features/room/modals/ClearActionNotificationsConfirmation';
import LockRoomConfirmation from 'features/room/modals/LockRoomConfirmation';
import UnLockRoomConfirmation from 'features/room/modals/UnLockRoomConfirmation';
import RecordingE2eeConsentModal from 'features/recorder/modals/RecordingE2eeConsentModal';
import RecordingPlanLengthLimitModal from 'features/recorder/modals/RecordingPlanLengthLimitModal';
import RecordingFreeTotalLimitModal from 'features/recorder/modals/RecordingFreeTotalLimitModal';
import AdmitAllWaitingParticipants from 'features/users/modals/AdmitAllWaitingParticipants';
import RejectAllWaitingParticipants from 'features/users/modals/RejectAllWaitingParticipants';
import BulkMuteRemoteAudioConfirmation from 'features/room/modals/BulkMuteRemoteAudioConfirmation';
import CreateBreakoutRoomsModal from 'features/breakout-rooms/modals/CreateBreakoutRoomsModal';
import DeleteBreakoutRoomConfirmation from 'features/breakout-rooms/modals/DeleteBreakoutRoomConfirmation';
import RenameBreakoutRoomModal from 'features/breakout-rooms/modals/RenameBreakoutRoomModal';
import ClearBreakoutRoomsConfirmation from 'features/breakout-rooms/modals/ClearBreakoutRoomsConfirmation';
import AssignUserToRoomModal from 'features/breakout-rooms/modals/AssignUserToRoomModal';
import MoveUserToAnotherRoomModal from 'features/breakout-rooms/modals/MoveUserToAnotherRoomModal';
import AddUsersToRoomModal from 'features/breakout-rooms/modals/AddUsersToRoomModal';
import DeleteQuestionConfirmation from 'features/qa/modals/DeleteQuestionConfirmation';
import DeleteAnswerConfirmation from 'features/qa/modals/DeleteAnswerConfirmation';
import CreatePollModal from 'features/polls/modals/CreatePollModal';
import EditPollModal from 'features/polls/modals/EditPollModal';
import DeletePollConfirmation from 'features/polls/modals/DeletePollConfirmation';
import StartTranscriptionConfirmation from 'features/transcripts/modals/StartTranscriptionConfirmation';

const componentsMap: Partial<Record<ModalName, React.ComponentType>> = {
  roomSettings: RoomSettingsModal,
  audioPermissionsError: AudioPermissionsError,
  videoPermissionsError: VideoPermissionsError,
  screensharePermissionsError: ScreensharePermissionsError,
  mediaDevicesBlocked: BlockedDevicesModal,
  muteRemoteAudioConfirmation: MuteRemoteAudioConfirmation,
  muteRemoteVideoConfirmation: MuteRemoteVideoConfirmation,
  unmuteRemoteAudioConfirmation: UnmuteRemoteAudioConfirmation,
  unmuteRemoteVideoConfirmation: UnmuteRemoteVideoConfirmation,
  endSessionConfirm: EndSessionConfirmation,
  peerConnectionFailure: PeerConnectionFailureModal,
  recordingConsent: RecordingConsentModal,
  recordingE2eeConsent: RecordingE2eeConsentModal,
  recordingPlanLengthLimit: RecordingPlanLengthLimitModal,
  recordingFreePlanTotalLimit: RecordingFreeTotalLimitModal,
  whiteboardE2eeConfirmation: WhiteboardE2eeConfirmationModal,
  recordingFailure: RecordingFailureModal,
  recordingFailureRuntime: RecordingFailureRuntimeModal,
  stopRecordingConfirmation: StopRecordingConfirmation,
  kickUserConfirmation: KickUserConfirmation,
  manageRole: ManageRole,
  roomE2eeCode: RoomSecurityCodeModal,
  inviteUser: InviteUserModal,
  clearActionNotificationsConfirmation: ClearActionNotificationsConfirmation,
  lockRoomConfirmation: LockRoomConfirmation,
  unlockRoomConfirmation: UnLockRoomConfirmation,
  admitAllWaitingParticipants: AdmitAllWaitingParticipants,
  rejectAllWaitingParticipants: RejectAllWaitingParticipants,
  broadcasterLimitReached: BroadcasterLimitReachedModal,
  bulkMuteRemoteAudioConfirmation: BulkMuteRemoteAudioConfirmation,
  createBreakoutRooms: CreateBreakoutRoomsModal,
  deleteBreakoutRoom: DeleteBreakoutRoomConfirmation,
  renameBreakoutRoom: RenameBreakoutRoomModal,
  clearBreakoutRooms: ClearBreakoutRoomsConfirmation,
  assignUserToBreakoutRoom: AssignUserToRoomModal,
  moveUserToAnotherBreakoutRoom: MoveUserToAnotherRoomModal,
  addUsersToBreakoutRoom: AddUsersToRoomModal,
  deleteQuestion: DeleteQuestionConfirmation,
  deleteAnswer: DeleteAnswerConfirmation,
  createPoll: CreatePollModal,
  editPoll: EditPollModal,
  deletePoll: DeletePollConfirmation,
  startTranscriptionConfirmation: StartTranscriptionConfirmation,
};

const RoomModalManager = () => {
  const { name, data: props } = useAppSelector(selectActiveModal);

  const Modal = useMemo(() => {
    if (!name) {
      return null;
    }

    return componentsMap[name];
  }, [name]);

  if (!Modal) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Modal {...props} />
    </Suspense>
  );
};

export default RoomModalManager;
