import { createAction } from '@reduxjs/toolkit';
import {
  ContentLibraryRawFile,
  FileOpenedPayload,
  SignalingFileOpenedPayload,
} from 'features/content-library/types';

export const signalingFilesReceived = createAction<{ files: ContentLibraryRawFile[] }>(
  'signaling/filesReceived'
);

export const signalingFileOpened = createAction<SignalingFileOpenedPayload>('signaling/fileOpened');
export const signalingFileClosed = createAction('signaling/fileClosed');

export const fileOpenRequested = createAction<FileOpenedPayload>(
  'contentLibrary/fileOpenRequested'
);
