import {
  fileOpenRequested,
  signalingFileClosed,
  signalingFileOpened,
  signalingFilesReceived,
} from 'features/content-library/actions';
import { closeFileSaga } from 'features/content-library/sagas/closeFileSaga';
import { onFilesReceivedSaga } from 'features/content-library/sagas/onFilesReceivedSaga';
import { takeEvery } from 'redux-saga/effects';
import { fileClosed } from '../contentLibrarySlice';
import { onFileOpenedSaga } from './onFileOpenedSaga';
import { onSignalingFileClosedSaga } from './onSignalingFileClosedSaga';
import { onSignalingFileOpenedSaga } from './onSignalingFileOpenedSaga';

export default function* contentLibraryWatcher() {
  yield takeEvery(signalingFilesReceived, onFilesReceivedSaga);
  yield takeEvery(signalingFileOpened, onSignalingFileOpenedSaga);
  yield takeEvery(signalingFileClosed, onSignalingFileClosedSaga);
  yield takeEvery(fileOpenRequested, onFileOpenedSaga);
  yield takeEvery(fileClosed, closeFileSaga);
}
