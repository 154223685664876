import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  ContentLibraryFile,
  ContentLibraryFileList,
  ContentLibraryState,
  FileOpenedPayload,
  FilesReceivedPayload,
  SignalingFileOpenedPayload,
} from './types';

export const initialState: ContentLibraryState = {
  files: {
    byId: {},
    allIds: [],
  },
  openFile: null,
  activePage: 0,
  showThumbnails: true,
};

export const contentLibrarySlice = createSlice({
  name: 'contentLibrary',
  initialState,
  reducers: {
    filesReceived(state, action: PayloadAction<FilesReceivedPayload>) {
      state.files.byId = action.payload.byId;
      state.files.allIds = action.payload.allIds;
    },
    fileAdded(state, action: PayloadAction<SignalingFileOpenedPayload>) {
      const { id, url, thumbnailUrl, page, totalPages, name } = action.payload;
      if (!state.files.byId[id]) {
        state.files.byId[id] =
          totalPages === 1
            ? {
                id,
                name,
                url,
                thumbnailUrl,
              }
            : {
                id,
                name,
                pages: Array.from({ length: totalPages }, () => ({ thumbnailUrl: '', url: '' })),
              };

        state.files.allIds.push(id);
      }

      if (totalPages > 1) {
        (state.files.byId[id] as ContentLibraryFileList).pages[page] = { thumbnailUrl, url };
      }
    },
    fileOpened(state, action: PayloadAction<FileOpenedPayload>) {
      state.openFile = action.payload.id;
      state.activePage = action.payload.page;
    },
    thumbnailsToggled(state) {
      state.showThumbnails = !state.showThumbnails;
    },
    fileClosed(state) {
      state.openFile = null;
    },
  },
});

export const { filesReceived, fileAdded, fileOpened, thumbnailsToggled, fileClosed } =
  contentLibrarySlice.actions;

export default contentLibrarySlice.reducer;

export const selectContentLibraryFileIds = (state: RootState) => state.contentLibrary.files.allIds;
export const selectContentLibraryFileEntries = (state: RootState) =>
  state.contentLibrary.files.byId;

export const selectShowContentLibraryThumbnails = (state: RootState) =>
  state.contentLibrary.showThumbnails;

export const selectContentLibraryFiles = createSelector(
  [
    selectContentLibraryFileIds,
    selectContentLibraryFileEntries,
    (state, searchQuery: string) => searchQuery.toLowerCase(),
  ],
  (ids, entries, searchQuery) =>
    ids
      .map((id) => {
        if (searchQuery) {
          return entries[id]?.name.toLowerCase().includes(searchQuery) ? entries[id] : null;
        }
        return entries[id];
      })
      .filter(Boolean) as ContentLibraryFile[]
);

export const selectContentLibraryActivePage = (state: RootState) => state.contentLibrary.activePage;

export const selectOpenFile = (state: RootState) => {
  if (state.contentLibrary.openFile) {
    return state.contentLibrary.files.byId[state.contentLibrary.openFile];
  }

  return null;
};
