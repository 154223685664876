import { Box, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectDeviceType } from 'features/application/applicationSlice';
import { FilePanel } from 'features/content-library/file-panel/FilePanel';
import {
  selectGridPanel,
  selectMaximizedContent,
} from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { selectContentWidth } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { GridPanelControls } from 'features/layout/GridPanel/GridPanelControls';
import { hoverParentStyles, useHoverParent } from 'features/layout/useHoverParent';
import { WhiteboardPanel } from 'features/whiteboard/WhiteboardPanel';
import { selectShowWhiteboardLoader } from 'features/whiteboard/whiteboardSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { ComponentType, ForwardedRef, useMemo, useState } from 'react';
import { Axis, Resizable, ResizeCallbackData } from 'react-resizable';
import { useAppSelector } from 'store/hooks';
import { layoutConfig } from 'utils/layout';
import cx from 'clsx';

const Panel = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'relative',
  padding: `${theme.room.tileGap}px`,
  paddingRight: `${theme.room.tileGap * 4}px`,
}));

const HandleOuter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show?: boolean }>(({ theme, show }) => ({
  height: '100%',
  width: '10px',
  position: 'absolute',
  right: 0,
  top: 0,
  display: show ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'ew-resize',
  zIndex: 2,
  '.resize-pill': {
    opacity: 1,
    height: '36px',
    width: '6px',
    borderRadius: '.25rem',
    border: `1px solid ${theme.room.tileBackground}`,
    backgroundColor: '#fff',
  },
}));

const CustomHandle = React.forwardRef(
  (props: Partial<{ handleAxis: Axis; show: boolean }>, ref: ForwardedRef<HTMLDivElement>) => {
    const { handleAxis, show, ...restProps } = props;
    return (
      <HandleOuter ref={ref} className={`handle-${handleAxis}`} show={show} {...restProps}>
        <Box className="resize-pill" />
      </HandleOuter>
    );
  }
);

const panelMap: Record<
  GridPanelName,
  ComponentType<{ size: { width?: number; height?: number } }>
> = {
  whiteboard: WhiteboardPanel,
  'content-library': FilePanel,
};

interface GridPanelProps {
  roomSize: {
    width?: number;
    height?: number;
  };
}
export const GridPanel = ({
  roomSize: { width: roomWidth, height: roomHeight },
}: GridPanelProps) => {
  const showLoader = useAppSelector(selectShowWhiteboardLoader);

  const containerWidth = roomWidth || window.innerWidth;
  const maximizedContent = useAppSelector(selectMaximizedContent);
  const contentWidth = useAppSelector(selectContentWidth);

  const [panelSize, setPanelSize] = useState({
    width: containerWidth * layoutConfig.defaultPinWidth,
    height: roomHeight,
  });

  const maxContentWidth = containerWidth - layoutConfig.minSidebarWidth;

  const activePanel = useAppSelector(selectGridPanel);

  const { isMobile } = useBreakpoints();

  const maximizeWB = maximizedContent === 'whiteboard' || isMobile;

  const deviceType = useAppSelector(selectDeviceType);

  const isMobileBreakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const width = useMemo(() => {
    if (activePanel === 'content-library') {
      return maxContentWidth;
    }

    return Math.min(panelSize.width, maxContentWidth);
  }, [panelSize, maxContentWidth, activePanel]);

  const { className, ...mouseCallbacks } = useHoverParent('gridPanel');

  if (!activePanel || (maximizedContent === 'stream' && contentWidth === 1)) {
    return null;
  }

  if (!(roomWidth && roomHeight)) {
    return null;
  }

  const onResize = (data: ResizeCallbackData) => {
    setPanelSize(data.size);
  };

  const Component = panelMap[activePanel];

  const hasDragHandle = activePanel === 'whiteboard' && !maximizeWB && !showLoader;

  return (
    <Resizable
      width={width}
      height={panelSize.height}
      onResize={(e, data) => onResize(data)}
      maxConstraints={[maxContentWidth, roomHeight]}
      minConstraints={[containerWidth * layoutConfig.minContentWidth, 0]}
      resizeHandles={deviceType === 'mobile' || isMobileBreakpoint ? [] : ['e']}
      axis="x"
      handle={<CustomHandle show={hasDragHandle} />}
    >
      <Panel
        className={cx(className, { 'fullscreen-panel': maximizeWB })}
        sx={{ minWidth: width }}
        {...mouseCallbacks}
      >
        <Box sx={hoverParentStyles}>
          <Component size={{ width: roomWidth, height: roomHeight }} />
          <GridPanelControls dragHandle={hasDragHandle} />
        </Box>
      </Panel>
    </Resizable>
  );
};
